import axios from 'axios';

// Define the base URL from environment variables
const API_BASE_URL = process.env.API_BASE_URL || 'https://appadmin.dataacts.com';

// Set up Axios instance with default settings
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Request interceptor to add token to headers
apiClient.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
apiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Handle token refresh failure (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);



// Function to register a new user
export const registerUser = async (data: {
  fullname: string;
  email: string;
  password: string;
  organization: string;

}) => {
  try {
    const response = await apiClient.post('/api/signup/', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle login
export const loginUser = async (email: string, password: string ) => {
  try {
    const response = await apiClient.post('/api/login/', { email, password });
    localStorage.setItem('accessToken', response.data.access);
    localStorage.setItem('refreshToken',  response.data.refresh);
    localStorage.setItem('isOrganizationAdmin', response.data.is_organization_admin);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle logout
export const logoutUser = async () => {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('isOrganizationAdmin');
  } catch (error) {
    console.error("Logout failed", error);
    throw error;
  }
};

// Function to fetch profile data
export const fetchProfile = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    
    const response = await apiClient.get('/api/profile/', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch profile data", error);
    throw error;
  }
};

// Function to update profile data
export const updateProfile = async (data: Partial<{
  full_name: string;
  email: string;
  contact_number: string;
}>) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await apiClient.put('/api/profile/', data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};



// Function to refresh the access token
export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await apiClient.post('/api/token/refresh/', { refresh: refreshToken });
    const { access } = response.data;

    // Store new access token
    localStorage.setItem('accessToken', access);

    return access;
  } catch (error) {
    console.error("Token refresh failed", error);
    throw error;
  }
};


// Function to check organisation exists or not
export const checkOrganisationExists = async (organisation_url: string) => {

  try {
    const response = await apiClient.post('/api/organisation_exist/', { organisation_url });
    return response.data;
  } catch (error) {
    throw error;
  }

};


// Function to get all pricing plans
export const getPricingPlans = async () => {
  try {
    const response = await apiClient.get('/api/pricing/');
    return response.data;
  } catch (error) {
    console.error("Error fetching pricing data:", error);
    throw error;
  }
};

export const createSubscription = async (planId: string) => {
  try {
    const response = await apiClient.post('/api/create-subscription/', { plan_id: planId });
    return response.data;
  } catch (error) {
    throw error ? error : new Error('Failed to create subscription');
  }
};

export const verifySubscription = async (subscriptionId: string,
  razorpayPaymentId: string, razorpaySignature: string
) => {
  try {
    const response = await apiClient.post('/api/verify-subscription/', { 
      subscription_id: subscriptionId,
      razorpay_payment_id: razorpayPaymentId,
      razorpay_signature: razorpaySignature
     });
    return response.data;
  } catch (error) {
    throw error ? error : new Error('Failed to verify subscription');
  }
};



// Function to Confirm Invitation
export const acceptInvite = async (token: string) => {
  try {
    const response = await apiClient.post('/api/invite/', {
      invitation_token: token
    });
    return response.data;
  } catch (error) {
    console.error("Error accepting invite:", error);
    throw error;
  }
}

// Function to Confirm Invitation
export const resetPassword = async (token: string, new_password: string) => {
  try {
    const response = await apiClient.post('/api/reset-password/', {
      reset_token: token,
      new_password: new_password
    });
    return response.data;
  } catch (error) {
    console.error("Error accepting invite:", error);
    throw error;
  }
}
// Function to Forgot password Invitation
export const forgotPassword = async (email: string) => {
  try {
    const response = await apiClient.post('/api/forgot-password/', { email });
    return response.data;
  } catch (error) {
    console.error('Error in forgot password:', error);
    throw error;
  }
};

export const inviteTeamMembers = async (emails: string[]) => {
  try {
    const response = await apiClient.post('/api/invite-team-members/', { emails });
    return response.data;
  } catch (error) {
    console.error('Error inviting team members:', error);
    throw error;
  }
};

export const cancelSubscriptionPlan = async () => {
  try {
    const response = await apiClient.post('/api/cancel-subscription/', {});
    return response.data;
  } catch (error) {
    console.error('Error cancel subscription:', error);
    throw error;
  }
};

export const subscriptionActivity = async () => {
  try {
    const response = await apiClient.get('/api/subscription-activity/', {});
    return response.data;
  } catch (error) {
    console.error('Error subscription activity:', error);
    throw error;
  }
};


import React, { useState } from "react";
import { AxiosError } from "axios";
import InputField from "components/fields/InputField";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgotPassword } from 'services/apiService';

interface ErrorResponse {
  message: string;
}

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); 

    // Check if the email is empty
    if (!email) {
      toast.error("Please enter your email."); // Show error message if email is not provided
      return; // Stop the function execution
    }

    setLoading(true);
    try {
      await forgotPassword(email);
      toast.success("Password reset email sent successfully!");
    } catch (err) {
      const error = err as AxiosError;
      if (error.response && error.response.data) {
        const errorData = error.response.data as ErrorResponse;
        toast.error(errorData.message || "Failed to reset password.");
      } else {
        toast.error("An error occurred. Please try again."); // Fallback error message
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-white dark:bg-darkPrimary px-4 md:px-0">
      {/* Forgot Password section */}
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h4 className="mb-4 text-3xl font-bold text-navy-700 dark:text-white">
          Forgot Password
        </h4>
        <p className="mb-6 text-base text-gray-800 dark:text-gray-300">
          Enter your email to reset your password!
        </p>

        {/* Email Input Field */}
        <InputField
          variant="auth"
          extra="mb-4"
          label="Email*"
          placeholder="mail@example.com"
          id="email"
          type="text"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />

        <button 
          className="w-full flex items-center justify-center rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSubmit} // Call handleSubmit on button click
          disabled={loading}
        >
          {loading ? ( 
            <svg 
              className="animate-spin h-5 w-5 text-white" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor" 
                d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
              ></path>
            </svg>
          ) : (
            "Reset Password"
          )}
        </button>

        <div className="mt-4 text-center">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            No need to reset?
          </span>
          <Link
            to="/signin"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign In
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;

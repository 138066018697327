import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/avatar.png";
import { logoutUser, fetchProfile } from "services/apiService";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation(); // Get the current route
  const isDashboardPage = location.pathname.startsWith('/dashboard/main') ? true : false;
  useEffect(() => {
    const getProfileData = async () => {
      try {
        const profile = await fetchProfile();
        const nameParts = profile.full_name.split(' ');
        const firstName = nameParts[0]; // Take the first part of the full name
        setFirstName(firstName);
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      }      
    };

    getProfileData();
    const handleScroll = () => {
      console.log(window.scrollY);
      if (window.scrollY > 0) {
        
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };
    if (isDashboardPage) {
      window.addEventListener("scroll", handleScroll);
    }
  
    return () => {
      if (isDashboardPage) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      await logoutUser();
      window.location.reload();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };
  


  

  return (
    <nav className={`sticky top-4 z-40 mb-4 flex flex-row flex-wrap items-center justify-between rounded-xl p-2 ${isDashboardPage ? '' : 'backdrop-blur-xl'}`}>
      <div className="ml-[6px]">
        {/* Collapse Button */}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        {!isDashboardPage && (
          <div className="h-6 w-[224px] pt-1">
            <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              DataActs
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}/{" "}
              </span>
            </a>
            <Link
              className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {brandText}
            </Link>
          </div>
        )}
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {isVisible && brandText}
          </Link>
        </p>
      </div>


      <div
      className={`relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-cyan-900 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2 ${
        isVisible ? "" : "hidden"
      }`}
    >
      <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div>
    
      {/* Dark Mode Toggle */}
      <div
        className="cursor-pointer text-gray-600"
        onClick={() => {
          if (darkmode) {
            document.body.classList.remove("dark");
            setDarkmode(false);
          } else {
            document.body.classList.add("dark");
            setDarkmode(true);
          }
        }}
      >
        {darkmode ? (
          <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
        ) : (
          <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
        )}
      </div>
    
      {/* Profile & Dropdown */}
      <Dropdown
        button={
          <img className="h-10 w-10 rounded-full" src={avatar} alt="Profile" />
        }
        children={
          <div className="flex h-40 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
            <div className="ml-4 mt-3">
              <div className="flex items-center gap-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  👋 Hey, {firstName || "User"}
                </p>
              </div>
            </div>
            <div className="mt-2 h-px w-full bg-gray-200 dark:bg-white/20 " />
            <div className="ml-4 mt-3 flex flex-col">
              <a
                href="/dashboard/profile/"
                className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
              >
                Profile Settings {isDashboardPage}
              </a>
              <a
                href="/dashboard/invite-team/"
                className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
              >
                Invite Team
              </a>
              <a
                href=" "
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
                className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
              >
                Log Out
              </a>
            </div>
          </div>
        }
        classNames={"py-2 top-2 -left-[180px] w-max"}
      />
    </div>
    
    </nav>
  );
};

export default Navbar;

import React, { useState } from 'react';
import Card from 'components/card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { inviteTeamMembers } from 'services/apiService'

const InviteTeamForm = () => {
  const [emails, setEmails] = useState(''); // Comma-separated emails
  const [loading, setLoading] = useState(false);

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    const emailArray = emails.split(',').map(email => email.trim()).filter(email => email);
    console.log("Inviting emails:", emailArray);

    setLoading(true);
    
    try {

      
      const data = await inviteTeamMembers(emailArray);
      toast.success(data.message);
      setEmails('');
    } catch (error) {
      // Handle error
      toast.error("Error sending invites. Please try again.");
    } finally {
      // Reset the loading state
      setLoading(false);
    }
  };

  return (
    <>
      <Card extra={"w-full h-full p-3"}>
        {/* Header */}
        <div className="mt-2 mb-6 w-full">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Invite Your Team Members
          </h4>
        </div>

        <form onSubmit={handleInvite} className="grid gap-4 px-2">
          {/* Email Input Field */}
          <div className="flex flex-col">
            <label htmlFor="emails" className="text-sm text-gray-600">
              Enter Email(s) (comma separated)
            </label>
            <input
              type="text"
              id="emails"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              className="mt-1 w-full border border-gray-300 rounded-md px-1 py-1 bg-white dark:bg-navy-700 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter email addresses"
              required
            />
          </div>
          {/* "w-full border border-gray-300 rounded-md px-1 py-1 bg-white dark:bg-navy-700 text-base focus:outline-none focus:ring-2 focus:ring-blue-500" */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading} // Disable button while loading
              className={`mt-6 w-full rounded-lg ${loading ? 'bg-brand-400' : 'bg-brand-500'} py-2 text-white hover:bg-brand-600`}
            >
              {loading ? (
                <svg 
                className="animate-spin h-5 w-5 mx-auto text-white" 
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              ) : (
                "Submit"
              )}
            </button>
            
          </div>
        </form>

        {/* Information Points */}
        <div className="mt-6">
          <h5 className="text-lg font-semibold text-navy-700 dark:text-white">Information Points:</h5>
          <ul className="list-disc ml-5 mt-2 text-gray-700 dark:text-gray-300">
            <li>Ensure all email addresses are valid and active.</li>
            <li>Separate multiple emails with commas for proper processing.</li>
            <li>You will receive confirmation for each invite sent.</li>
            <li>If an email is invalid, it will be excluded from the invite list.</li>
          </ul>
        </div>
      </Card>
      <ToastContainer />
    </>
  );
};

export default InviteTeamForm;

import React from "react";
import MainDashboard from "views/dashboard/default";
import Profile from "views/dashboard/profile";
import Pricing from "views/dashboard/pricing";
import Subscription from "views/dashboard/subscription";
import InviteTeam from "views/dashboard/invite";
import {
  MdOutlineHome,
  MdOutlineShoppingCart,
  MdPersonOutline,
  MdPriceChange,
  MdOutlineHandshake,
} from "react-icons/md";


const isOrganizationAdmin = localStorage.getItem("isOrganizationAdmin") === "true";


const adminRoutes = [
  {
    name: "Dashboard",
    layout: "/dashboard",
    path: "main",
    icon: <MdOutlineHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Pricing",
    layout: "/dashboard",
    path: "pricing",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Pricing />,
    secondary: true,
  },
  {
    name: "Invite Team",
    layout: "/dashboard",
    path: "invite-team",
    icon: <MdOutlineHandshake className="h-6 w-6" />,
    component: <InviteTeam />,
  },
  {
    name: "Profile",
    layout: "/dashboard",
    path: "profile",
    icon: <MdPersonOutline className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Your Subscribed Plan",
    layout: "/dashboard",
    path: "subscription",
    icon: <MdPriceChange className="h-6 w-6" />,
    component: <Subscription />,
    secondary: true,
  },
];

const nonAdminRoutes = [
  {
    name: "Dashboard",
    layout: "/dashboard",
    path: "*",
    icon: <MdOutlineHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Profile",
    layout: "/dashboard",
    path: "profile",
    icon: <MdPersonOutline className="h-6 w-6" />,
    component: <Profile />,
  }
];

const routes = isOrganizationAdmin ? adminRoutes : nonAdminRoutes;

export default routes;

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { acceptInvite } from 'services/apiService';
import { toast } from 'react-toastify';

const InviteHandler: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const handleInvite = async () => {
      try {
        const data = await acceptInvite(token);
        // Redirect to signup page with organization name pre-filled
        navigate(`/register?organization=${data.origin_name}`);
      } catch (error) {
        toast.error("Error processing the invitation.");
        // Optionally, handle the error state or redirect to an error page
        navigate('/register');
      }
    };

    if (token) {
      handleInvite();
    }
  }, [token, navigate]);

  return (
    <div>
      <p>Processing your invitation...</p>

    </div>
    
  );
};

export default InviteHandler;

import React, { useEffect, useState } from "react";
import IframeView from "views/dashboard/default/components/IframeView";
import Pricing from "views/dashboard/pricing";
import { fetchProfile } from "services/apiService";

interface Organization {
  org_iframe: string;
  org_metabase_name: string;
}

interface Profile {
  organization: Organization;
  is_subscribed: boolean;
}

const Dashboard = () => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState<boolean | null>(true);

  useEffect(() => {
    const getProfileData = async () => {
      setLoading(true);
      try {
        const profileData = await fetchProfile();
        setProfile(profileData);
      } catch (error) {
        console.error("Failed to fetch profile data.", error);
      } finally {
        setLoading(false);
      }
    };
    getProfileData();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <div className="mt-5 grid-cols-12 gap-5 md:grid-cols-12">
        { 
        profile ? (
          profile.is_subscribed ? (
            <IframeView profile={profile} />
          ) : (
            <Pricing />
          )
        ) : (
          <Pricing />
        )
      }
      </div>
    </div>
  );
};

export default Dashboard;

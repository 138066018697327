import React from "react";
import logoImage from "assets/img/logo.png"; // Import your local image

// Define the props type
interface LogoIconProps {
  className?: string;
  imgSrc?: string; 
}

const LogoIcon: React.FC<LogoIconProps> = ({ className, imgSrc }) => {
  return (
    <div className="w-full max-w-md">
      <div className="mb-4 text-centers items-center">
          <div className={`flex items-center justify-center ${className}`}>
            <img src={logoImage} alt="Logo" className="h-12 w-100" />
          </div>
        </div>
      </div>
    
  );
};

export default LogoIcon;

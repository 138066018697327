import React, { useEffect, useState } from "react";
import SubscriptionCard from "./components/SubscriptionForm";
import { fetchProfile } from "services/apiService";
import tableDataComplex from "../tables/variables/tableDataComplex";
import SubscriptionTable from "./components/SubscriptionTable";


const SubscriptionDashboard = () => {
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const fetchedProfile = await fetchProfile();
        setProfile(fetchedProfile);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    getProfile();
  }, []);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (    
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
      <SubscriptionCard subscriptionPlan={profile.organization.subscription_plan} />
      <SubscriptionTable />
    </div>
  );
};

export default SubscriptionDashboard;

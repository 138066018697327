import { useState, useEffect } from "react";
import Card from "components/card";

interface Organization {
  org_iframe: string;
  org_metabase_name: string;
}

interface Profile {
  organization: Organization;
}

interface IframeViewProps {
  profile: Profile
}

const IframeView: React.FC<IframeViewProps> = ({ profile }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profile) {
      setLoading(false);
    }
  }, [profile]);

  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const isDark = document.body.classList.contains("dark");
    setDarkMode(isDark);

    const observer = new MutationObserver(() => {
      setDarkMode(document.body.classList.contains("dark"));
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const iframeSrc = darkMode
    ? profile?.organization?.org_iframe.replace("transparent", "night")
    : profile?.organization?.org_iframe;

  return (
    <Card extra="!p-[20px] text-center">
      {/* Check if profile is loading */}
      {loading ? (
        <div className="mt-2 mb-8 w-full text-center">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Loading Dashboard...
          </h4>
        </div>
      ) : (
        <>
          {/* Display the iframe once profile is loaded */}
          <div className="mt-2 mb-8 w-full text-left">
            <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
              {profile?.organization?.org_metabase_name}
            </h4>
          </div>
          <div className="mt-1">
            <iframe
              src={iframeSrc}
              width="100%"
              height="1200px"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={profile?.organization?.org_metabase_name}
            ></iframe>
          </div>
        </>
      )}
    </Card>
  );
};

export default IframeView;

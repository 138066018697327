import {
  MdCheckCircle,
  MdOutlineAttachMoney,
} from "react-icons/md";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { cancelSubscriptionPlan } from 'services/apiService';
import { useState } from 'react'; // Import useState for managing loading state

export interface SubscriptionPlan {
  name: string;
  amount_in_inr: string;
  amount_in_usd: string;
  tag_line_for_plan: string;
  interval: string;
  descriptions: Array<{ point: string }>;
}

interface SubscriptionCardProps {
  subscriptionPlan?: SubscriptionPlan | null;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ subscriptionPlan }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [isCancelling, setIsCancelling] = useState(false); // Add loading state for cancel action

  const handleUpgradeClick = () => {
    // Redirect to the pricing page
    navigate("/dashboard/pricing");
  };

  const handleCancelClick = async () => {
    // Set loading state to true when cancellation starts
    setIsCancelling(true);
    try {
      await cancelSubscriptionPlan(); 
      navigate("/dashboard/pricing")
    } catch (error) {
      // Handle any errors here
      console.error("Error cancelling subscription:", error);
    } finally {
      // Set loading state to false when the request is completed
      setIsCancelling(false);
    }
  };

  return (
    <Card extra="!p-[20px] text-center">
      {/* Check if there is an active subscription plan */}
      {subscriptionPlan ? (
        <>
          {/* Header with Pricing Plan Details */}
          <div className="flex justify-between">
            <div className="text-left">
              <h3 className="text-2xl font-bold text-navy-700 dark:text-white">{subscriptionPlan.name}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">{subscriptionPlan.tag_line_for_plan}</p>
            </div>
            <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
              <MdOutlineAttachMoney className="h-6 w-6" />
            </button>
          </div>

          {/* Pricing Information */}
          <div className="mt-[20px]">
            <p className="text-5xl font-bold text-navy-700 dark:text-white">
              ${subscriptionPlan.amount_in_usd}
              <span className="text-sm font-normal text-gray-600 dark:text-gray-300">/{subscriptionPlan.interval}</span>
            </p>
          </div>

          {/* Features List */}
          <div className="mt-[20px] text-left">
            {subscriptionPlan?.descriptions?.map((description, i) => (
              <div key={i} className="flex items-center gap-2">
                <MdCheckCircle className="text-green-500" />
                <p className="text-sm text-gray-600 dark:text-gray-300">{description.point}</p>
              </div>
            ))}
          </div>

          {/* Upgrade Button */}
          <button
            className="mt-6 w-full rounded-lg bg-brand-500 py-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
            onClick={handleUpgradeClick}
          >
            Upgrade Now
          </button>

          {/* Cancel Button with Loader */}
          {isCancelling ? (
            <button className="mt-6 w-full rounded-lg bg-red-500 py-2 text-white duration-200 bg-red-600 active:bg-red-700" disabled>
              Cancelling...
            </button>
          ) : (
            <button
              className="mt-6 w-full rounded-lg bg-red-500 py-2 text-white transition duration-200 hover:bg-red-600 active:bg-red-700"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          )}
        </>
      ) : (
        /* No active plan message */
        <div className="mt-[20px]">
          <h3 className="text-2xl font-bold text-navy-700 dark:text-white">No active plan for your organization</h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 mt-4">Please contact support or choose a plan to get started.</p>
        </div>
      )}
    </Card>
  );
};

export default SubscriptionCard;

import React from "react";
import Card from "components/card";
import { toast } from 'react-toastify';

export interface SubscriptionPlan {
  name: string;
  amount_in_inr: string;
  amount_in_usd: string;
  tag_line_for_plan: string;
  interval: string;
}

export interface Organization {
  name: string;
  slug: string;
  subscription_plan: SubscriptionPlan;
}

export interface Profile {
  email: string;
  full_name: string;
  organization: Organization;
  contact_number: string;
  is_subscribed: boolean;
  date_joined: string;
}

interface InformationProps {
  profile: Profile;
}

const Information: React.FC<InformationProps> = ({ profile }) => {
  const copyToClipboard = async () => {
    const url = `https://app.dataacts.com/${profile.organization.slug}`;
    try {
      await navigator.clipboard.writeText(url);
      toast.success("Link copied to clipboard!");
    } catch (err) {
      toast.error("Failed to copy the link.");
    }
  };
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Profile Information
        </h4>
      </div>

      <div className="grid grid-cols-2 gap-4 px-2">
        {/* Full Name */}
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Full Name</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {profile.full_name}
          </p>
        </div>

        {/* Email */}
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Email</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {profile.email}
          </p>
        </div>

        {/* Contact */}
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Contact Number</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {profile.contact_number}
          </p>
        </div>
      </div>

      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Organization Information
        </h4>
      </div>

      <div className="grid grid-cols-2 gap-4 px-2">
        {/* Organization Name */}
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Organization Name</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {profile.organization.name}
          </p>
        </div>

        {/* Organization Slug */}
        {/* <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Organization Slug</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            https://app.dataacts.com/{profile.organization.slug}
          </p>
        </div> */}
        <div
          className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none cursor-pointer"
          onClick={copyToClipboard}
        >
          <p className="text-sm text-gray-600">Organization Slug</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            https://app.dataacts.com/{profile.organization.slug}
          </p>
        </div>

        {/* Subscription Plan */}
        {/* <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Subscription Plan</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {profile.organization.subscription_plan.name} ({profile.organization.subscription_plan.amount_in_inr} INR / {profile.organization.subscription_plan.amount_in_usd} USD, {profile.organization.subscription_plan.interval})
          </p>
        </div> */}
      </div>

    </Card>
  );
};

export default Information;

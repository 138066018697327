import React, { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import lightLogo from "assets/img/DataActs_Logo_White.png";
import darkLogo from "assets/img/DataActs_Logo.png";
import routes from "routes";

interface SidebarProps {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const isDark = document.body.classList.contains("dark");
    setDarkMode(isDark);

    const observer = new MutationObserver(() => {
      setDarkMode(document.body.classList.contains("dark"));
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);


  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex flex-col h-full bg-white pb-0 shadow-sm shadow-white/5 transition-all dark:!bg-cyan-900 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl"
        onClick={onClose}
      >
        <HiX />
      </span>
      <div className="mx-[56px] mt-[40px] flex items-center justify-between">
        <div className="ml-1 mt-1 h-2">
          <img
            className="w-[190px]"
            src={darkMode ? lightLogo : darkLogo}
            alt="DataActs"
          />
        </div>
        
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}
      <ul className={`flex-grow mb-auto pt-1 transition-all duration-300`}>

        <Links routes={routes} />
      </ul>
      {/* Footer section */}
      <footer
        style={{ backgroundColor: "#06283D" }} 
        className="p-4 text-center dark:bg-cyan-800"
      >
        <p className="text-sm text-white dark:text-gray-300">
          © 2025 DataActs, Inc. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Sidebar;
import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";
import { resetPassword } from "services/apiService";
import { ToastContainer, toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await resetPassword(token, password);
      setMessage("Password reset successful!");
      toast.success("Password reset successful!");
      navigate("/signin");
    } catch (err) {
      console.error("Password reset error:", err);
      setError("Failed to reset password. Please try again.");
      toast.error("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-white dark:bg-darkPrimary px-4 md:px-0">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h4 className="mb-4 text-3xl font-bold text-navy-700 dark:text-white">
          Reset Password
        </h4>

        {error && <div className="text-red-500">{error}</div>}
        {message && <div className="text-green-500">{message}</div>}

        {/* New Password Input with Visibility Toggle */}
        <div className="relative mb-4">
          <InputField
            variant="auth"
            extra="mb-4" // Added extra prop here
            label="New Password*"
            placeholder="Enter your new password"
            id="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
          <button
            type="button"
            className="absolute right-3 top-10 text-gray-500"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FaEyeSlash className="text-black" size={25} style={{ color: '#4F61FF' }}/>
            ) : (
              <FaEye className="text-black" size={25} style={{ color: '#4F61FF' }}/>
            )}
          </button>
        </div>

        {/* Confirm Password Input with Visibility Toggle */}
        <div className="relative mb-4">
          <InputField
            variant="auth"
            extra="mb-4" // Added extra prop here
            label="Confirm Password*"
            placeholder="Confirm your new password"
            id="confirm-password"
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setConfirmPassword(e.target.value)
            }
          />
          <button
            type="button"
            className="absolute right-3 top-10 text-white text-gray-500"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? (
              <FaEyeSlash className="text-black" size={25} style={{ color: '#4F61FF' }}/>
            ) : (
              <FaEye className="text-black" size={25} style={{ color: '#4F61FF' }}/>
            )}
          </button>
        </div>

        <button
          className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300"
          onClick={handleSubmit}
        >
          Reset Password
        </button>

        <div className="mt-4 text-center">
          <Link
            to="/signin"
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Back to Sign In
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;

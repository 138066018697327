import React, { useState, useEffect } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { updateProfile } from "services/apiService";
import { toast } from 'react-toastify';

export interface SubscriptionPlan {
  name: string;
  amount_in_inr: string;
  amount_in_usd: string;
  tag_line_for_plan: string;
  interval: string;
}

export interface Organization {
  name: string;
  slug: string;
  subscription_plan: SubscriptionPlan;
}

export interface Profile {
  email: string;
  full_name: string;
  organization: Organization;
  contact_number: string;
  is_subscribed: boolean;
  date_joined: string;
}


interface GeneralProps {
  profile: Profile;
  updateProfileData: (updatedProfile: Profile) => Promise<void>;
}


const General: React.FC<GeneralProps> = ({ profile, updateProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [contactNumber, setContactNumber] = useState<string>(() => {
    if (profile.contact_number) {
      const parts = profile.contact_number.split("-");
      return parts[1] || "";
    }
    return "";
  });

  const [countryCode, setCountryCode] = useState<string>(() => {
    if (profile.contact_number) {
      const parts = profile.contact_number.split("-");
      return parts[0] || "+91";
    }
    return "+91";
  });

  const [updatedProfile, setUpdatedProfile] = useState<Profile>(profile);

  useEffect(() => {
    setUpdatedProfile(profile);
    if (profile.contact_number) {
      setContactNumber(profile.contact_number.split("-")[1] || "");
      setCountryCode(profile.contact_number.split("-")[0] || "+91");
    }
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUpdatedProfile(prevProfile => ({
      ...prevProfile,
      [id]: value,
    }));
  };

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.target.value);
    setContactNumber(""); // Reset contact number when changing the country code
  };

  const handleContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Ensure only numeric input
    if (!/^\d*$/.test(value)) {
      return; // Ignore non-numeric input
    }

    // Determine the max length based on the selected country code
    let maxLength = Infinity;
    switch (countryCode) {
      case "+91": // India
        maxLength = 10;
        break;
      case "+44": // UK
        maxLength = 11;
        break;
      case "+1": // USA/Canada
        maxLength = 10;
        break;
      case "+61": // Australia
        maxLength = 10;
        break;
      default:
        maxLength = Infinity;
    }

    // Enforce max length
    if (value.length <= maxLength) {
      setContactNumber(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const updatedData = {
        ...updatedProfile,
        contact_number: `${countryCode}-${contactNumber}`,
      };
      setLoading(true);
      await updateProfile(updatedData);
      // Fetch updated profile
      await updateProfileData(updatedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error updating profile:", error);
    }
  };

  return (
    <>
      <Card extra={"w-full h-full p-3"}>
        {/* Header */}
        <div className="mt-2 mb-8 w-full">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Update Profile
          </h4>
          <p className="mt-2 px-2 text-base text-gray-600 dark:text-gray-300">
            Edit the fields below to update your profile information.
          </p>
        </div>
        {/* Form */}
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 px-2">
            {/* Full Name */}
            <InputField
              id="full_name"
              label="Full Name"
              extra="flex flex-col items-start justify-center rounded-2xl bg-white dark:bg-navy-800 bg-clip-border px-3 py-4"
              placeholder="Enter full name"
              variant="auth"
              type="text"
              value={updatedProfile.full_name}
              onChange={handleChange}
            />
            {/* Email */}
            <InputField
              id="email"
              label="Email"
              extra="flex flex-col items-start justify-center rounded-2xl bg-white dark:bg-navy-800 bg-clip-border px-3 py-4"
              placeholder="Enter your email"
              variant="auth"
              type="email"
              value={updatedProfile.email}
              onChange={handleChange}
            />
            
            {/* Contact Number with Country Code */}
            <div className="flex flex-col items-start justify-center rounded-2xl bg-white dark:bg-navy-800 bg-clip-border px-3 py-4">
            <label htmlFor="phone" className="text-sm font-medium mb-2">
              Contact Number
            </label>
            <div className="flex w-full border rounded-lg bg-gray-200 dark:bg-navy-700 ">
              <select
                id="countryCode"
                className="border-none rounded-l-lg px-4 py-3 bg-white-200 dark:bg-navy-700 text-base"
                value={countryCode}
                onChange={handleCountryCodeChange}
                style={{ minWidth: '80px' }}
              >
                <option value="+1">+1 </option>
                <option value="+91">+91 </option>
                <option value="+44">+44 </option>
                <option value="+61">+61 </option>
              </select>
              <input
                id="contact_number"
                type="text"
                className="w-full border border-gray-300 rounded-md px-1 py-1 bg-white dark:bg-navy-700 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your Contact Number"
                value={contactNumber}
                onChange={handleContactNumberChange}
              />
            </div>
          </div>
          </div>
          <div className="mt-4 flex justify-end px-2">
            <button
              type="submit"
              className={`mt-6 w-60 rounded-lg ${loading ? 'bg-brand-400' : 'bg-brand-500'} py-2 text-white hover:bg-brand-600`}
            >
               {loading ? (
                <svg 
                className="animate-spin h-5 w-5 mx-auto text-white" 
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
              ) : (
                "Update Profile"
              )}
              
            </button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default General;
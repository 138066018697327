import PricingCard from "views/dashboard/pricing/components/PricingCard";


const PricingDashboard = () => {
  return (
    <div>
      <div className="mt-5 grid grid-cols-12 gap-5 md:grid-cols-2">
        <PricingCard />
      </div>
    </div>
  );
};

export default PricingDashboard;

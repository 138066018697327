export const loadRazorpay = (callback: () => void) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      callback();
    };
    script.onerror = () => {
      console.error('Razorpay SDK failed to load. Are you online?');
    };
    document.body.appendChild(script);
  };
  